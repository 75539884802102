import * as React from 'react';
import PropTypes from "prop-types";
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import {cardCondition} from "../../utils/card-condition";
import {FlagIcon} from "../../components/FlagIcon";
import {EditButton} from "./buttons/EditButton";
import {DeleteButton} from "./buttons/DeleteButton";
import {emptyLists} from "./listsClient";
import {useListItems} from "./hooks";
import {useAuth} from "../../context/authContext";
import {AddCardVariantButton} from "./buttons/AddCardVariantButton";
import {useUser} from "../users/hooks";

const actionButtons = (params) => {
    return (
        <>
            <EditButton item={params.row} listName={params.row.listName} />
            <DeleteButton listItemId={params.row.id} listName={params.row.listName} />
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay>
            <p>Click the + symbol to add a card</p>
        </GridOverlay>
    )
}

export const MyListItemsTable = ({ cardId, isVirtualCard }) => {
    const listNames = isVirtualCard ? [`MyCollection`] : Object.keys(emptyLists);
    const {isLoggedIn} = useAuth();
    const user = useUser();
    const {isLoading, data: rows} = useListItems({userId:(user && user.data && user.data.data && user.data.data.id), listNames, cardId});

    const columns = [
        { field: `listName`, headerName: `List`, description: `List`, width: 160, renderCell: (params) => emptyLists[params.row.listName].title, sortable: false },
        { field: `conditionText`, headerName: `Condition`, description: `Condition`, width: 130, valueGetter: (params) => cardCondition[params.row.condition ? params.row.condition.id : 0], sortable: false },
        { field: `style`, headerName: `Style`, description: `Style`, width: 120, valueGetter: (params) => params.row.foiled ? `Foiled` : params.row.reverse_foiled ? `Reverse foiled` : `Standard`, sortable: false },
        { field: `language`, headerName: `Language`, description: `Language`, width: 130, renderCell: (params) => <FlagIcon className="mr-2" code={params.row.language} key={params.row.language} />, sortable: false },
        { field: `signed`, headerName: `Signed`, description: `Signed`, width: 105, valueGetter: (params) => params.row.signed ? `Y` : `N`, sortable: false },
        { field: `quantity`, headerName: `Qty`, description: `Quantity`, width: 80, sortable: false },
        { field: `Actions`, headerName: `Actions`, description: `Actions`, width: 120, renderCell: (params) => actionButtons(params), sortable: false },
    ];

    return (
        <div className={`relative`} style={{ height: `25em` }}>
            <DataGrid
                columnBuffer={2}
                columns={columns}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                disableColumnMenu
                disableSelectionOnClick
                headerHeight={52}
                hideFooterPagination
                hideFooterRowCount
                hideFooterSelectedRowCount
                loading={isLoading}
                rowHeight={48}
                rows={isLoggedIn() ? rows : []}
                scrollbarSize={15}
            />
            <AddCardVariantButton cardId={cardId} />
        </div>
    );
}

MyListItemsTable.propTypes = {
    cardId: PropTypes.number,
    isVirtualCard: PropTypes.bool,
}
