import * as React from "react";
import {Fab} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";

import {useCard} from "../../cards/hooks";
import {ListButton} from "./ListButton";

const useStyles = makeStyles(theme => ({
    fab: {
        position: `absolute`,
        zIndex: 9,
    },
}));

export const AddCardVariantButton = ({ cardId, listName }) => {
    const classes = useStyles();
    const { data: result } = useCard(cardId);
    return result ? (
        <Fab aria-label="Add" className={`bottom-0 m-2 right-0 ${classes.fab}`} color="secondary" component="div" size="small">
            <ListButton availableLanguages={result.data.availableLanguages} card={result.data} color="inherit" heading={`Add`} icon={<AddIcon />} listName={listName} tooltip={`Add`} />
        </Fab>
    ) : null;
}
