import * as React from 'react';
import PropTypes from "prop-types";
import { upperFirst } from "lodash";
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import {cardCondition} from "../../utils/card-condition";
import {FlagIcon} from "../../components/FlagIcon";
import {UserWidget} from "../users/UserWidget";
import {AddToCartButton} from "../cart/AddToCartButton";
import {AddStockItemButton} from "../stock/AddStockItemButton";
import {useStockItems} from "../../hooks/useCommerceLayer";
import {useCard} from "../cards/hooks";
import {createSkuFilter, splitSku} from "../products/utils";
import {getCardImageUrl} from "../../utils/get-card-image-url";
import {Price} from "../products/Price";
import {cardStyleByLetter} from "../../utils/card-style";
import {EditStockButton} from "../stock/EditStockButton";
import {DeleteStockButton} from "../stock/DeleteStockButton";
import {useUser} from "../users/hooks";

const ActionButtons = ({ card, row }) => {
    const user = useUser();
    const userId = user && user.data && Number(user.data.data.id);
    const sellerId = Number(row.attributes.reference);
    return userId === sellerId ? <EditActions card={card} row={row} /> : <AddToCartAction card={card} row={row} sellerId={sellerId} />
}
const AddToCartAction = ({ card, row, sellerId }) => {
    const cardAssetUrl = card.image && card.image.data.asset_url;
    const product = {
        imageUrl: getCardImageUrl({ assetUrl:cardAssetUrl, fullUrl:card.image_url }),
        name: card.name,
        quantityAvailable: row.attributes.quantity,
        sellerId: sellerId,
        skuCode: row.attributes.sku_code,
    }
    return <AddToCartButton product={product} />
}
const EditActions = ({ row }) => {
    return (
        <>
            <EditStockButton item={row} />
            <DeleteStockButton skuCode={row.attributes.sku_code} stockItemId={row.id} />
        </>
    )
}
const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay>
            <p>This card not currently offered for sale</p>
        </GridOverlay>
    )
}

export const SaleListItemsTable = ({ cardId, isVirtualCard }) => {
    const { cardIsLoading, data: card } = useCard(cardId);
    const skuFilter = card ? createSkuFilter({gameRef:card.data.set.series.game.ref, cardId}) : ``;
    const filters = card ? {limit:25, Api:{...skuFilter}} : ``;
    const {isLoading, isSuccess, data: rows} = useStockItems({filters});
    const columns = [
        { field: `Seller`, headerName: `Seller`, description: `Seller`, width: 160, renderCell: (params) => <UserWidget userId={Number.parseInt(params.row.attributes.reference)} variant="small" />, sortable: false },
        { field: `conditionText`, headerName: `Condition`, description: `Condition`, width: 130, valueGetter: (params) => cardCondition[splitSku(params.row.attributes.sku_code).conditionId], sortable: false },
        { field: `style`, headerName: `Style`, description: `Style`, width: 120, valueGetter: (params) => upperFirst(cardStyleByLetter[splitSku(params.row.attributes.sku_code).style]), sortable: false },
        { field: `language`, headerName: `Language`, description: `Language`, width: 130, renderCell: (params) => <FlagIcon className="mr-2" code={splitSku(params.row.attributes.sku_code).language} key={splitSku(params.row.attributes.sku_code).language} />, sortable: false },
        { field: `signed`, headerName: `Signed`, description: `Signed`, width: 105, valueGetter: (params) => splitSku(params.row.attributes.sku_code).signed ? `Y` : `N`, sortable: false },
        { field: `quantity`, headerName: `Qty`, description: `Quantity`, width: 80, valueGetter: (params) => params.row.attributes.quantity, sortable: false },
        { field: `price`, headerName: `Price`, description: `Price`, width: 90, renderCell: (params) => <Price skuCode={params.row.attributes.sku_code} userId={Number.parseInt(params.row.attributes.reference)} />, sortable: false },
        { field: `postage`, headerName: `Postage`, description: `Postage`, width: 120, sortable: false },
        { field: `Actions`, headerName: `Actions`, description: `Actions`, width: 120, renderCell: (params) => <ActionButtons card={card.data} row={params.row} />, sortable: false},
    ];

    return (
        <div className={`relative`} style={{ height: `25em` }}>
            <DataGrid
                columnBuffer={2}
                columns={columns}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                disableColumnMenu
                disableSelectionOnClick
                headerHeight={52}
                hideFooterRowCount
                hideFooterSelectedRowCount
                loading={cardIsLoading || isLoading}
                pageSize={25}
                paginationMode="server"
                rowCount={(rows ? rows.meta.record_count : 0)}
                rowHeight={60}
                rows={isVirtualCard || !isSuccess ? [] : rows.data}
                rowsPerPageOptions={[]}
                scrollbarSize={15}
            />
            {!isVirtualCard && <AddStockItemButton cardId={cardId} />}
        </div>
    );
}

SaleListItemsTable.propTypes = {
    cardId: PropTypes.number,
    isVirtualCard: PropTypes.bool,
}

