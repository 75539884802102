import * as React from "react";
import {Fab} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import {StockItemDialog} from "./StockItemDialog";
import {useUIDispatch} from "../../app/uiContext";
import {useAuth} from "../../context/authContext";

const useStyles = makeStyles(theme => ({
    fab: {
        position: `absolute`,
        zIndex: 9,
    },
}));

export const AddStockItemButton = ({ cardId }) => {
    const {isLoggedIn} = useAuth();
    const uiDispatch = useUIDispatch();
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleShowDialog = () => {
        isLoggedIn() ? setDialogOpen(true) : uiDispatch({type:`SHOW_LOGIN`});
    }
    const handleClose = () => {
        setDialogOpen(false);
    };


    return (
        <>
            <Fab aria-label="Add" className={`bottom-0 m-2 right-0 ${classes.fab}`} color="secondary" onClick={handleShowDialog} size="small">
                <AddIcon />
            </Fab>
            <StockItemDialog
                cardId={cardId}
                handleClose={handleClose}
                heading="Add stock item"
                open={dialogOpen}
            />
        </>
    );
}
